<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                非凸科技受邀出席中泰证券第四届 XTP 开发者大会
              </p>
              <div class="fst-italic mb-2">2022年11月6日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >中泰XTP大会</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/18/01-公司介绍.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    11月6日，非凸科技受邀出席中泰证券举办的“第四届XTP开发者大会”，众多优秀私募管理人和工程师们齐聚一堂，共同探索数智交易行业的技术创新，以及未来机遇和挑战。
                  </p>
                  <p>
                    中泰证券金融科技委员会主任、XTP品牌创始人何波表示，“Rust在今年成为XTP的重要组件，Rust的优点在于高性能，没有垃圾回收，与C++一样安全可靠。”而非凸科技作为Rust先行者，敢为人先，在持续为金融机构提供优质的算法交易解决方案。
                  </p>
                  <p>
                    非凸科技首席架构师乔丹在会上分享了“Rust在算法交易中的实际应用与积极效应”，为大家带来满满的技术干货！
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/18/02-个人介绍.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                   <p>
                    在非凸算法交易服务中，Rust有哪些全栈应用实践？从桌面客户端到交易执行服务端，从离线模型训练、策略研究和回测到在线预测因子生成和风控、结算等，Rust都扮演着重要角色，助益良多。
                  </p>
                  <p>
                    非凸科技采用Rust FFI方式进行行情、交易API的接入，且基于Rust的安全性和稳定性，在低延迟方面做到了极致，对各个组件进行了优化。乔丹表示，“非凸内部高度应用的Rust进程间通信组件‘XSHM’，基于低延迟高吞吐的一写多读消息队列，支持全用户态和零拷贝，还针对x86_64、AArch64 架构分别做了CPU Cache 友好方面的优化，以及后续的组件，也会陆续开源出来。”另外，关于UDP组播，非凸科技也在比较底层的通信层面协议栈参数进行了优化，包括多路行情的冗余互备。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/18/03-算法服务.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    然后，在模型训练和在线预测方面也做了一些优化。比如预测，森林模型展开成Rust代码后，预测的代码可能特别长，但是编译后的执行效能比动态加载的方式更快；比如互联网新闻因子，大规模的新闻实时spider以及NLP分析，将其做成交易信号的一个增强，来指导交易。“然而要实现这些训练需要一个物质基础，那就是我们的自建机房，配备8TB内存+8显卡定制机型，分布式超大规模的 LSTM 等训练，单台价值百万，支持全天候多周期预测。”乔丹补充道。
                  </p>
                  <p>
                    在非凸科技，不管是策略研究员还是开发工程师，都要写Rust。也就是说，离线回测、在线模拟盘或实盘策略都需要代码统一，不仅能降低多栈维护成本，还能降低代码细节不一致导致的实验失真。
                  </p>
                  <p>
                    从交易过程到桌面客户端和风控服务端，非凸科技形成了一个比较完备的异常的事前、事中、事后全方位监控。非凸风控服务端和桌面客户端，是基于Rust异步编程框架开发，风控逻辑可插拔，兼顾部署灵活性与执行性能。
                  </p>
                  <p>
                    非凸内部使用最广泛的Rust组件，是Rust高性能日志库ftlog。目前ftlog已开源，支持时间切分和受限写入，且单次调用耗时8倍提升。乔丹表示，“非凸的工程师将会持续迭代技术，不断优化细节，保持开源精神，希望可以与更多的开发者们进行探讨和交流。”
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/18/04-现场.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    未来，非凸科技将持续精进Rust的应用场景，开发一种基于Rust宏的语言，RTL（Rust Trading Language）），只描述交易相关的事情，可以降低策略研究员写Rust代码的门槛。“RTL在编译时展开为Rust代码，静态编译为native二进制，将进一步提升非凸策略研究、回测和实盘的迭代效率。”乔丹解释道。
                  </p>
                  <p>
                    除此之外，非凸科技将在高性能和可视化方面做突破，即开发一种在浏览器内基于Rust + WebAssembly 的高性能可视化工具，可以帮助策略研究员极大地提高迭代效率。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/18/05-展台.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    精进技术，持续迭代。非凸科技仍将基于Rust高性能，输出更高效、更安全、更全面的技术服务，为金额机构赋能，携手打造健康可持续发展的数智交易新生态。
                  </p>
                  <p>
                    非凸最新算法已上线中泰XTP系统，欢迎管理人申请试用。
                  </p>
                  <p>
                    <a href="https://www.bilibili.com/video/BV19W4y147KE/?vd_source=48940ef7f313a1bdf0a465665bca355a" target="_blank">现场直播回放</a>
                  </p>
                 
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News13",
};
</script>

<style></style>
